.card {
  display: inline-block;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.2);
  padding: 2rem;
  margin: 2rem;
  width: 35rem;
  vertical-align: top;
}

.card__affixinfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.2);
  padding: 1rem;
  margin: 1rem;
  width: 29rem;
}

.card__affixinfo_color1 {
  color: #EE7700;
}

.card__affixinfo_color2 {
  color: #DDAA00;
}

.card__affixinfo_color3 {
  color: #008800;
}

.card__affixinfo_color4 {
  color: #008888;
}

.card__affixinfo_color5 {
  color: #7A0099;
}

.card__top {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}

.card__top__img {
  border-radius: 50%;
  margin-right: 2rem;
  width: 8rem;
  height: 8rem;
}

.card__top__title {
  font-size: 3em;
  font-weight: bold;
}

.card__flag {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
}

.card__flag__span {
  background-color: #9b4dca;
  border-radius: 1rem;
  color: #fff;
  font-size: 1.6rem;
  margin-right: 0.5rem;
  margin-bottom: 1.5rem;
  padding: 5px;
  border: none;
}

.card__description {
  font-size: 1.8rem;
  line-height: 2;
}

.card__power__1 {
  color: #888888;
}

.card__power__2 {
  color: #0044BB;
}

.card__power__3 {
  color: #227700;
}

.card__power__4 {
  color: #EE7700;
}

/* 滑鼠滑過狀態 */
.card:hover {
  transition: all 0.3s ease;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.card:hover .card__top__img {
  transform: rotate(360deg);
  transition: all 0.5s ease;
}

.card:hover .card__top__title {
  color: #4a4a4a;
}

.card:hover .card__description {
  color: #343a40;
}

/* 觸碰狀態 */
.card:active,
.card:focus {
  transform: translateY(0px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* 手指滑動 */
.card {
  touch-action: pan-y;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
  will-change: transform;
}

.card__top__img,
.card__top__title,
.card__description {
  touch-action: none;
  transition: none;
}