*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  font-size: 62.5%;
}

section.rails {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
  gap: 30px;
  margin: 0 auto;
  /* max-width: 100px; */
  justify-items: center;
  padding: 30px;
}
