
.filter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 3rem;
    background-color: #f5f5f5;
    padding: 1rem;
  }
  
  .filter__btn {
    font-size: 1.8rem;
    border-radius: 5rem;
    padding: 1rem 2rem;
    margin-right: 1rem;
    border: none;
    background-color: #fff;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .filter__btn:hover {
    background-color: #333;
    color: #fff;
  }
  
  .filter__btn:active {
    background-color: #000;
    color: #fff;
    transform: scale(0.95);
  }
  
  .filter__active {
    background-color: #9b4dca;
    color: white;
  }
  